<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>SQL 排程列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div>
          <h4 class="mb-2 font-weight-bold">SQL 排程列表</h4>
        </div>
        <div>
          <b-input-group class="mb-2">
            <b-button
              v-if="$permissions.has($permissions.consts.SQL_RUNNER_MODIFY)"
              class="flex-shrink-0 mb-2 mb-xl-0"
              variant="primary"
              :to="{ name: 'SqlRunnerCreate' }"
              ><i class="fa fa-plus"></i>新增 SQL 排程</b-button
            >
          </b-input-group>
        </div>
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="sqlRunners"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    v-if="$permissions.has($permissions.consts.SQL_RUNNER_EXECUTE)"
                    variant="inverse-success"
                    @click="executeSqlRunner(data.item)"
                  >
                    執行
                  </b-button>
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'SqlRunnerView',
                      params: { sql_runner_id: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="$permissions.has($permissions.consts.SQL_RUNNER_MODIFY)"
                    variant="inverse-warning"
                    :to="{
                      name: 'SqlRunnerEdit',
                      params: { sql_runner_id: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="$permissions.has($permissions.consts.SQL_RUNNER_MODIFY)"
                    variant="inverse-danger"
                    @click="deleteSqlRunner(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="getSqlRunners"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sqlRunnerApi from "@/apis/sql-runner";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      sqlRunners: [],
      fields: [
        {
          key: "mail_subject",
          label: "mail 標題",
        },
        {
          key: "description",
          label: "描述",
        },
        {
          key: "format",
          label: "格式",
        },
        {
          key: "send_when_empty",
          label: "無資料時是否寄送",
          formatter: (value) => {
            return value ? '是' : '否';
          },
        },
        {
          key: "cron_schedule",
          label: "排程",
        },
        {
          key: "is_enabled",
          label: "是否啟用",
          formatter: (value) => {
            return value ? '是' : '否';
          },
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getSqlRunners()
    },
    async getSqlRunners() {
      this.showLoading = true
      try {
        const { data } = await sqlRunnerApi.getSqlRunners()
        this.sqlRunners = data.data
        this.totalRows = data.meta.total
      } catch (error) {
        console.error(error)
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "取得 Sql 排程錯誤",
        })
      }
      this.showLoading = false
    },
    async executeSqlRunner(sqlRunner) {
      this.showLoading = true
      try {
        await sqlRunnerApi.execute(sqlRunner)
      this.$swal.fire({
        title: "執行成功",
        type: "success",
      });
      } catch (error) {
        console.error(error)
        this.$swal.fire({
          title: "執行錯誤",
          type: "error",
        })
      }
      this.showLoading = false
    },
    async deleteSqlRunner(sqlRunner) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>mail 標題：${sqlRunner.mail_subject}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await sqlRunnerApi.removeSqlRunner(sqlRunner);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + sqlRunner.mail_subject,
              });
              this.getSqlRunners();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
